import React, { FC, useEffect, useRef, useState } from 'react'
import "./staffList.scss"
import AppHeader from 'shared/components/AppHeader'
import { useHistory, useLocation } from 'react-router-dom'
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import StaffsList from 'views/Hospitals/HospitalDetails/StaffsAndPatients/StaffsList';
import StaffService from 'services/HospitalService/staff.service';
import staffIcon from 'assets/svg/staff.svg'

interface StaffListProps { }

const StaffList: FC<StaffListProps> = (props) => {

  const { } = props

  const history = useHistory();
  const location = useLocation<any>();
  const rootRef = useRef<null | HTMLDivElement>(null);
  const search = (location?.state?.search) || "";
  const page = parseInt(location?.state?.currentPage || location?.search.split("=")[1]) || 1;
  const [searchText, setSearchText] = useState<string>(search);
  const year = location.state?.year
  const month = location.state?.month

  const { fetchStaffs, tableLoading, staffList, totalItems } = StaffService();

  const handleStaffSearch = (searchText: string) => {
    history.push({
      pathname: appRoutes.STAFFS_LIST,
      search: `?page=${1}&search=${searchText}`,
      state: { currentPage: 1, search: searchText, year, month }
    })
  };

  useEffect(() => {
    fetchStaffs({
      search: searchText,
      page: page,
      pageSize: 10,
      year,
      month
    });
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    })
  }, [location])

  const handlePageChange = (page: number, pageSize?: number) => history.push(
    {
      pathname: appRoutes.STAFFS_LIST,
      search: `?page=${page}${search !== "" ? `&search=${search}` : ''}`,
      state: { currentPage: page, search: search, year, month }
    });

  return (
    <div className="app-wrapper staff-list" ref={rootRef}>
      <AppHeader
        title="Staff List"
        isSearchEnabled={true}
        searchValue={searchText}
        setSearchValue={setSearchText}
        handleSearch={handleStaffSearch}
        showGoBack={false}
        iconImgPath={staffIcon}
      />

      <StaffsList
        fetchStaffs={fetchStaffs}
        tableLoading={tableLoading}
        staffList={staffList}
        showAllStaff={true}
        totalItems={totalItems}
        currentPage={page}
        handlePageChange={handlePageChange}
      />
    </div>
  )
}

export default StaffList