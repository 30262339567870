import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { MetaModel } from "../../models/meta.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Department } from "../../models/Hospital/department.model";
import { PaginationModel } from "../../models/pagination.model";
import { ArticleTagModel } from "../../models/ArticleTag/articleTag.model";
import { FaqTagModel } from "../../models/FaqTag/faqTag.model";

const MetaService = () => {
  //roles
  const [rolesMeta, setRolesMeta] = useState<Array<MetaModel>>([]);

  const [currentRolesPage, setCurrentRolesPage] = useState<number>(1);

  const [totalRoleItems, setTotalRoleItems] = useState<number>(0);

  const [specialitiesMetaLoading, setSpecialitiesMetaLoading] = useState(false);

  const fetchRoles = (
    hospitalId: string,
    search?: string,
    page?: number,
    pageSize?: number,
    isMeta?: boolean
  ) => {
    let apiUrl =
      generatePath(ApiRoutes.EMPLOYEE_ROLES, { id: hospitalId }) +
      `?${search ? `search=${search}&` : ""}${page ? `page=${page}&page_size=${pageSize}` : ""}${hospitalId ? `&hospital_id=${hospitalId}` : ""}
      `;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const roles: any = deserialize(
          MetaModel,
          response.data["employee_roles"]
        );
        const paginationData = deserialize(PaginationModel, response.data);
        setRolesMeta([...rolesMeta, ...roles]);
        setCurrentRolesPage(paginationData?.page);
        setTotalRoleItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  //languages
  const [languagesMeta, setLanguagesMeta] = useState<Array<MetaModel>>([]);

  const [currentLanguagesPage, setCurrentLanguagesPage] = useState<number>(1);

  const [totalLanguageItems, setTotalLanguageItems] = useState<number>(0);

  const fetchLanguages = (
    hospitalId: string,
    search?: string,
    page?: number,
    pageSize?: number,
    isMeta?: boolean
  ) => {
    let apiUrl =
      generatePath(ApiRoutes.LANGUAGES, { id: hospitalId }) +
      `?${search ? `search=${search}&` : ""}${page ? `page=${page}&page_size=${pageSize}` : ""
      }`;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const languages: any = deserialize(
          MetaModel,
          response.data["languages"]
        );
        const paginationData = deserialize(PaginationModel, response.data);
        setLanguagesMeta([...languagesMeta, ...languages]);
        setCurrentLanguagesPage(paginationData?.page);
        setTotalLanguageItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  //specialities
  const [specialitiesMeta, setSpecialitiesMeta] = useState<Array<MetaModel>>(
    []
  );

  const [currentSpecialitiesPage, setCurrentSpecialitiesPage] =
    useState<number>(1);

  const [totalSpecialitiesItems, setTotalSpecialitiesItems] =
    useState<number>(0);

  const fetchSpecialities = (
    hospitalId: string,
    search?: string,
    page?: number,
    pageSize?: number,
    isMeta?: boolean
  ) => {
    let apiUrl =
      generatePath(ApiRoutes.SPECIALITIES, { id: hospitalId }) +
      `?${search ? `search=${search}&` : ""}${page ? `page=${page}&page_size=${pageSize}` : ""
      }`;
    return axiosInstance
      .get(apiUrl)
      .then((response) => {
        const specialities: any = deserialize(
          MetaModel,
          response.data["specialities"]
        );
        const paginationData = deserialize(PaginationModel, response.data['specialities']);
        setSpecialitiesMeta(specialities);
        setCurrentSpecialitiesPage(paginationData?.page);
        setTotalSpecialitiesItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const createSpecialities = (name: string) => {
    return axiosInstance
      .post(ApiRoutes.SPECIALITIES, { name })
      .then((response) => {
        const specialities: any = deserialize(
          MetaModel,
          response.data["specialities"]
        );
      })
      .catch((error) => { })
      .finally(() => { });
  };

  const deleteSpecialities = async (specialityId: string) => {
    setSpecialitiesMetaLoading(true)
    const path = generatePath(ApiRoutes.SPECIALITIES_SPECIFIC, { id: specialityId })
    return axiosInstance.delete(path)
      .then(() => {
        const tempSpecialityList = specialitiesMeta.filter((item) => item.value !== specialityId)
        setSpecialitiesMeta(JSON.parse(JSON.stringify(tempSpecialityList)))
      })
      .catch((e) => { throw (e) })
      .finally(() => {
        setSpecialitiesMetaLoading(false)
      });
  };

  //tags
  const [tagsMeta, setTagsMeta] = useState<Array<MetaModel>>([]);

  const [currentTagsPage, setCurrentTagsPage] = useState<number>(1);

  const [totalTagsItems, setTotalTagsItems] = useState<number>(0);

  const fetchTags = (params: any = {}) => {
    return axiosInstance
      .get(ApiRoutes.TAGS, { params: params })
      .then((response) => {
        const tags: any = deserialize(MetaModel, response.data["tags"]);
        const paginationData = deserialize(PaginationModel, response.data);
        setTagsMeta([...tags]);
        setCurrentTagsPage(paginationData?.page);
        setTotalTagsItems(paginationData?.total);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  // article tags

  const [articleTags, setArticleTags] = useState<ArticleTagModel>();

  const fetchArticleTags = (params: any = {}) => {
    return axiosInstance
      .get(ApiRoutes.ARTICLE_TAGS, { params })
      .then((response) => {
        const articleTags: ArticleTagModel = deserialize(
          ArticleTagModel,
          response.data["article_tags"] as any
        );
        setArticleTags(articleTags);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  // question tags

  const [faqTags, setFaqTags] = useState<FaqTagModel>();

  const fetchFaqTags = (params: any = {}) => {
    return axiosInstance
      .get(ApiRoutes.FAQ_TAGS, { params })
      .then((response) => {
        const questionTags: FaqTagModel = deserialize(
          FaqTagModel,
          response.data["question_tags"] as any
        );
        setFaqTags(questionTags);
      })
      .catch((error) => { })
      .finally(() => { });
  };

  return {
    fetchRoles,
    rolesMeta,
    currentRolesPage,
    totalRoleItems,
    fetchLanguages,
    languagesMeta,
    currentLanguagesPage,
    totalLanguageItems,
    fetchSpecialities,
    createSpecialities,
    specialitiesMeta,
    currentSpecialitiesPage,
    totalSpecialitiesItems,
    fetchTags,
    tagsMeta,
    currentTagsPage,
    totalTagsItems,
    fetchArticleTags,
    articleTags,
    fetchFaqTags,
    faqTags,
    deleteSpecialities,
    specialitiesMetaLoading,
  };
};

export default MetaService;
