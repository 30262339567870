import { useState } from 'react';
import { deserialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { DashboardStats } from '../../models/DashboardStats/dashboardStats.model';
import { JourneyStats } from '../../models/JourneyStats/journeyStats.model';

const DashboardService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [dashboardStats, setDashboardStats] = useState<DashboardStats>();

    const [employeesData, setEmployeesData] = useState<JourneyStats>()

    const [patientData, setPatientData] = useState<JourneyStats>()

    const [overallJourneyLoading, setOverallJourneyLoading] = useState<boolean>(false)

    const fetchDashboardStats = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_STATS)
            const data: DashboardStats = deserialize(DashboardStats, response.data) as DashboardStats;
            setDashboardStats(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchOverallJourney = async (params: {
        year?: string
    }) => {
        try {
            setOverallJourneyLoading(true)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_OVERALL_JOURNEY, { params })
            const employeeData: JourneyStats = deserialize(JourneyStats, response.data['employees']) as JourneyStats;
            const patientData: JourneyStats = deserialize(JourneyStats, response.data['patients']) as JourneyStats;
            setPatientData(patientData)
            setEmployeesData(employeeData)
        } catch (error) {
            console.log("Error is ", error)
        } finally {
            setOverallJourneyLoading(false)
        }
    }

    return {
        loading,
        fetchDashboardStats,
        dashboardStats,
        fetchOverallJourney,
        employeesData,
        patientData,
        overallJourneyLoading
    }
}

export default DashboardService