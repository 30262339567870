/* Auth Routes */
export const AUTH = "/auth";
export const REGISTER = AUTH + "/register";
export const LOGIN = AUTH + "/login";
export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const RESET_PASSWORD = AUTH + "/reset-password";

/* Dashboard Route */
export const DASHBOARD = "/dashboard";

/* Hospital routes */
export const HOSPITALS = "/hospitals";
export const SHOW_HOSPITAL = HOSPITALS + "/:id";
export const HOSPITAL_LOCATIONS = SHOW_HOSPITAL + "/locations";
export const HOSPITAL_DEPARTMENTS = SHOW_HOSPITAL + "/departments";
export const HOSPITAL_ROLE = SHOW_HOSPITAL + "/roles";

/* Staff routes */
export const STAFFS = SHOW_HOSPITAL + "/staffs"
export const STAFFS_LIST ="/staffs"
export const SHOW_STAFF = STAFFS + "/:staffId";

/* JourneyList routes */
export const JOURNEYS = "/journeys";
export const JOURNEY_FORM = JOURNEYS + "/:id";
export const JOURNEY_DETAILS = JOURNEYS + "/:id/info";

/* Support routes */
export const SUPPORT = "/support";

/* Article routes */
export const ARTICLES = "/content";
export const ARTICLE_FORM = "/content/:id";
export const ARTICLE_VIEW = "/content/:id/view";

/* Faq routes */
export const FAQ = "/faqs";
export const FAQ_FORM = "/faqs/:categoryId/question/:id";

/* JourneyList template routes */
export const JOURNEY_TEMPLATES = "/journey-templates";
export const SHOW_JOURNEY_TEMPLATES = JOURNEY_TEMPLATES + "/:id";

/*patients and caretakers*/
export const SHOW_CARETAKER = "/hospitals/:hospitalId/caretakers/:id";
export const SHOW_PATIENT = "/hospitals/:hospitalId/patients/:id";
export const PATIENT_LIST = "/patients";

// tags customisaton
export const TAGS = "/tags"
