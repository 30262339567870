import { serializable, alias, primitive, list, object } from 'serializr';
import { MetaModel } from "../meta.model";
import { Hospital } from 'models/Hospital/hospital.model';

export class Caretaker {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('firstname', primitive()))
    firstname?: string;

    @serializable(alias('lastname', primitive()))
    lastname?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('phone_number', primitive()))
    phone?: string;

    @serializable(alias('is_active', primitive()))
    isActive?: "1" | "0";

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('gender', primitive()))
    gender?: string;

    @serializable(alias('age', primitive()))
    age?: string;

    @serializable(alias('type_of_condition', object(MetaModel)))
    typeOfCondition?: MetaModel;

    @serializable(alias('type_of_condition_id', primitive()))
    typeOfConditionId?: string;

    @serializable(alias('languages', list(primitive())))
    languagesKnown?: Array<string>;

    @serializable(alias('bio', primitive()))
    bio?: string;

    @serializable(alias('dob', primitive()))
    dob?: string;

    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string;
}

export class Patient {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('id', primitive()))
    key?: number;

    @serializable(alias('firstname', primitive()))
    firstname?: string;

    @serializable(alias('lastname', primitive()))
    lastname?: string;

    @serializable(alias('fullname', primitive()))
    fullname?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('phone_number', primitive()))
    phone?: string;

    @serializable(alias('gender', primitive()))
    gender?: string;

    @serializable(alias('age', primitive()))
    age?: string;

    @serializable(alias('guardian', object(Caretaker)))
    caretaker?: Caretaker;

    @serializable(alias('languages', list(primitive())))
    languagesKnown?: Array<string>;

    @serializable(alias('display_picture_url', primitive()))
    displayPictureUrl?: string;

    @serializable(alias('bio', primitive()))
    bio?: string;

    @serializable(alias('allergies', primitive()))
    allergies?: string;

    @serializable(alias('dob', primitive()))
    dob?: string;

    @serializable(alias('past_medical_problems', primitive()))
    pastMedicalProblems?: string;

    @serializable(alias('previous_surgeries', primitive()))
    previousSurgeries?: string;

    @serializable(alias('hospitals', list(object(Hospital))))
    hospitals?: Hospital[];

}
