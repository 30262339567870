import React, { FC, useEffect, useState } from 'react'
import "./roleList.scss"
import { Col, Divider, Row } from 'antd'
import SkeletonLoader from '../../../../shared/components/SkeletonLoader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import PermissionDetails from './PermissionDetails'
import AddPermissionForm from './AddPermissionForm'
import CustomAccessService from 'services/CustomAccessService/customAccess.service'
import AppHeader from 'shared/components/AppHeader'
import ButtonComponent from 'shared/components/ButtonComponent'

interface RoleListProps { }

const RoleList: FC<RoleListProps> = (props) => {

    const [showCustomAccessForm, setShowCustomAccessForm] = useState<boolean>(false)
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location?.search)
    const role = urlParams.get("role") ?? ""
    const search = urlParams.get('search') || '';
    const [searchText, setSearchText] = useState<string>(search);
    const [activeCategoryId, setActiveCategoryId] = useState<string>(role);
    const { fetchEmployeeRoleList, employeeRoleList, loading } = CustomAccessService()
    const { id: hospitalId }: any = useParams();

    useEffect(() => {
        fetchEmployeeRoleList(hospitalId ?? "", searchText)
    }, [])

    useEffect(() => {
        const role = urlParams.get("role")
        if (!employeeRoleList || employeeRoleList.length === 0) return
        if (!role) {
            setActiveCategoryId(employeeRoleList[0].value || "")
        } else {
            const currentRoleIndex = employeeRoleList.findIndex((item) => item.value?.toString() === role.toString())
            if (currentRoleIndex === -1) {
                setActiveCategoryId(employeeRoleList[0].value || "")
            } else {
                setActiveCategoryId(role)
            }
        }
    }, [employeeRoleList, role])

    useEffect(() => {
        if (activeCategoryId === undefined) return
        history.replace({
            pathname: location.pathname,
            hash: 'role',
            search: `&role=${activeCategoryId}`
        });
    }, [activeCategoryId])

    const handleSearch = (searchText: string) => {
        fetchEmployeeRoleList(hospitalId ?? "", searchText)
        history.push({
            pathname: history.location.pathname,
            hash: "role",
            search: `?page=1&search=${searchText}&role=${role}`,
            state: { currentPage: 1 }
        });
    }

    return (
        <div className="app-wrapper faq">
            <AppHeader
                iconClassName=""
                title="Roles & Responsibilities"
                isSearchEnabled={true}
                buttonText="Role"
                buttonHandler={() => {
                    setShowCustomAccessForm(true)
                }}
                searchValue={searchText}
                setSearchValue={setSearchText}
                handleSearch={handleSearch}
                showGoBack={true}
            />
            <div className="role-list">
                <Row>
                    <Col span={7}>
                        <div className='role-list-sidebar-container'>
                            {
                                loading
                                    ? <SkeletonLoader avatar={false} rows={1} />
                                    : employeeRoleList.map((item, index) => <div key={index} className="role-sidebar__title">
                                        <div
                                            className={(item.value?.toString() === activeCategoryId.toString() ? ' active ' : ' ') + " role-title "}
                                            onClick={() => setActiveCategoryId(item.value || "")}
                                        >
                                            <span>{item.label}</span>
                                        </div>
                                        <Divider />
                                    </div>)
                            }
                            {
                                !loading && employeeRoleList.length === 0 && (
                                    <div>
                                        <span>No Roles Found, please add one</span>
                                        <div className='text-center mt-442'>
                                            <ButtonComponent
                                                text="Add Role"
                                                className="app-header__button"
                                                key={"header-button"}
                                                type="primary"
                                                onClick={() => {
                                                    setShowCustomAccessForm(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Col>
                    <Col span={16} offset={1}>
                        {
                            employeeRoleList.length > 0 && <PermissionDetails
                                fetchEmployeeRoleList={() => fetchEmployeeRoleList(hospitalId ?? "")}
                                roleId={activeCategoryId}
                                hospitalId={hospitalId}
                            />
                        }
                        <AddPermissionForm
                            showForm={showCustomAccessForm}
                            setShowForm={setShowCustomAccessForm}
                            fetchEmployeeRoleList={() => fetchEmployeeRoleList(hospitalId ?? "")}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default RoleList