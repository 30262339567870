import React, { FC, useEffect, useRef, useState } from "react";
import "./hospitals.scss";
import AppHeader from "../../shared/components/AppHeader";
import { Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import HospitalForm from "./HospitalForm";
import { Hospital } from "../../models/Hospital/hospital.model";
import DeleteConfirmation from "../../shared/components/DeleteConfirmation";
import { useHistory } from "react-router";
import { generatePath, useLocation } from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import TableComponent from "../../shared/components/TableComponent";
import HospitalService from "../../services/HospitalService/hospital.service";
import { formatPhoneNumberIntl } from 'react-phone-number-input'

interface HospitalsProps { }

const Hospitals: FC<HospitalsProps> = (props) => {
  const { } = props;

  const history = useHistory();
  const location = useLocation<any>();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get('page') || "1");
  const search = urlParams.get('search') || '';
  const showAddButton = urlParams.get('showAddButton') || "true"
  const rootRef = useRef<null | HTMLDivElement>(null);

  const {
    tableLoading,
    buttonLoading,
    hospitalList,
    fetchHospitals,
    deleteHospital,
    totalItems,
  } = HospitalService();

  const [hospital, setHospital] = useState<Hospital>({});
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>(search);

  useEffect(() => {
    fetchHospitals(searchText, page, 10);
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    });
  }, [location]);

  const handleHospitalSearch = (searchText: string) => {
    history.push({
      pathname: appRoutes.HOSPITALS,
      search: `?page=${1}&search=${searchText}&showAddButton=${showAddButton}`,
      state: { hospitalPage: 1, search: search }
    })
  };

  const columns = [
    {
      title: "HOSPITAL NAME",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text: string, record: Hospital) => (
        <span className="text-capitalize">{record?.name}</span>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 140,
      key: "email",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      className: "white-nowrap",
      key: "phone",
      width: 140,
      // render: (text: string, record: Hospital) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (record.extCode ? "#" + record.extCode : ""))
      render: (text: string, record: Hospital) => formatPhoneNumberIntl((record?.isdCode ? record.isdCode : "") + text + (!isNaN(parseInt(record.extCode || "")) ? "#" + record.extCode : ""))
    },
    {
      title: "LOCATION",
      dataIndex: "location",
      key: "location",
      width: 140,
      render: (text: string, record: Hospital) => (
        <span className="text-capitalize">{record?.location}</span>
      ),
    },
    // {
    //   title: "NO OF EMPLOYEES",
    //   key: "noOfEmployees",
    //   dataIndex: "noOfEmployees",
    // },
    {
      title: null,
      key: "action",
      width: 50,
      render: (text: string, record: Hospital) => (
        <div onClick={(event) => event.stopPropagation()}>
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            trigger={["click"]}
            className="manage-groups__details--action"
            onVisibleChange={(visible) => {
              setHospital(visible ? record : {});
            }}
          >
            <EllipsisOutlined className="table-menu" />
          </Dropdown>
        </div>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setShowForm(true)}>
        Edit
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const handlePageChange = (page: number, pageSize?: number) => {
    history.push({
      pathname: appRoutes.HOSPITALS,
      search: `?page=${page}${search !== '' ? `&search=${search}` : ''}&showAddButton=${showAddButton}`,
      state: { hospitalPage: page, search: search }
    })
  };

  return (
    <div className="app-wrapper hospitals" ref={rootRef}>
      <AppHeader
        iconClassName="icon-hospitals"
        title="Hospitals"
        isSearchEnabled={true}
        buttonText={showAddButton === "true" ? "Hospital" : undefined}
        buttonHandler={() => {
          setHospital({});
          setShowForm(true);
        }}
        searchValue={searchText}
        setSearchValue={setSearchText}
        handleSearch={handleHospitalSearch}
        showGoBack={false}
      />

      <TableComponent
        columns={columns}
        data={hospitalList}
        loading={tableLoading}
        handleRowClick={(record: Hospital) =>
          history.push({
            pathname: generatePath(appRoutes.SHOW_HOSPITAL, { id: record?.id }),
            hash: "staff",
            state: { hospitalPage: page, staffPage: 1, selectedTab: "staff", search: search }
          }
          )
        }
        onPageChange={handlePageChange}
        currentPage={page}
        total={totalItems}
      />

      <HospitalForm
        showForm={showForm}
        setShowForm={setShowForm}
        hospital={hospital}
        refreshList={fetchHospitals}
      />

      <DeleteConfirmation
        handleDelete={() => deleteHospital(hospital?.id)}
        setShowConfirmation={setShowDeleteConfirmation}
        showConfirmation={showDeleteConfirmation}
        entityName={"Hospital"}
        buttonLoading={buttonLoading}
        key="delete-confirmation"
        refresh={fetchHospitals}
      />
    </div>
  );
};

export default Hospitals;
