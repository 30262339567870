
import {serializable, alias, object, list, primitive} from 'serializr';

export class DashboardStats { 

	@serializable(alias('employee_count', primitive()))
	employeeCount?: string;

	@serializable(alias('patient_count', primitive()))
	patientCount?: string;

	@serializable(alias('journey_count', primitive()))
	journeyCount?: string;

	@serializable(alias('hospital_count', primitive()))
	hospitalCount?: string;

}