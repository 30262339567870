import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import { generatePath } from 'react-router-dom';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { CustomAccess } from '../../models/CustomAccess/customAccess.model';
import { PaginationModel } from './../../models/pagination.model';
import { MetaModel } from '../../models/meta.model';

const CustomAccessService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)

    const [customAccesses, setCustomAccesses] = useState<CustomAccess[]>([])

    const [customAccessesPagination, setCustomAccessesPagination] = useState<PaginationModel | null>()

    const [customAccess, setCustomAccess] = useState<CustomAccess | null>()

    const [employeeRoleList, setEmployeeRoleList] = useState<MetaModel[]>([]);

    const [employeeRole, setEmployeeRole] = useState<MetaModel>();

    const fetchCustomAccesses = async (params?: {
        search?: string,
        page?: number
    }) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(ApiRoutes.CUSTOM_ACCESS, { params })
            const data = deserialize(CustomAccess, response.data["CUSTOM_ACCESS"]) as CustomAccess[]
            const pagination = deserialize(PaginationModel, response.data["meta"])
            setCustomAccessesPagination(pagination)
            setCustomAccesses(data)
        } catch (error) {
            setCustomAccesses([])
        } finally {
            setLoading(false)
        }
    }

    const createCustomAccess = async (customAccess: CustomAccess, roleId: string, hospitalId: string) => {
        try {
            setButtonLoading(true)
            const payload = {
                ...serialize(CustomAccess, customAccess),
                employee_role_id: roleId,
                employee_role_name: roleId,
                hospital_id: hospitalId
            }
            const response = await axiosInstance.post(ApiRoutes.CUSTOM_ACCESS, payload)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(null)
        } finally {
            setButtonLoading(false)
        }
    }

    const updateCustomAccess = async (customAccess: CustomAccess, roleId: string, hospitalId: string) => {
        try {
            setButtonLoading(true)
            const payload = {
                ...serialize(CustomAccess, customAccess),
                employee_role_id: roleId,
                hospital_id: hospitalId
            }
            const API_URL = generatePath(ApiRoutes.CUSTOM_ACCESS_SPECIFIC, { id: String(customAccess.id) })
            const response = await axiosInstance.put(API_URL, payload)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(null)
        } finally {
            setButtonLoading(false)
        }
    }

    const deleteCustomAccess = async (customAccessId: string) => {
        try {
            setButtonLoading(true)
            const API_URL = generatePath(ApiRoutes.CUSTOM_ACCESS_SPECIFIC, { customAccessId })
            await axiosInstance.delete(API_URL)
            return true
        } catch (error) {
            return false
        } finally {
            setButtonLoading(false)
        }
    }

    const showCustomAccess = async (roleId: string) => {
        try {
            setLoading(true)
            const API_URL = generatePath(ApiRoutes.USER_ACCESS_PERMISSIONS, { id: roleId })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(CustomAccess, response.data) as CustomAccess;
            setCustomAccess(data)
        } catch (error) {
            setCustomAccess(undefined)
        } finally {
            setLoading(false)
        }
    }


    const fetchEmployeeRoleList = (
        hospitalId: string,
        search?: string
    ) => {
        setLoading(true)
        const url = generatePath(ApiRoutes.EMPLOYEE_ROLE, { id: hospitalId }) + `?${search ? `search=${search}&` : ""}${hospitalId ? `hospital_id=${hospitalId}`: ''}
        `
        return axiosInstance.get(url).then((response) => {
            const rolesList: MetaModel[] = deserialize(MetaModel, response.data["employee_roles"]) as MetaModel[];
            setEmployeeRoleList(rolesList);
            setLoading(false)
        })
    }

    const fetchEmployeeRole = (employeeRoleId: string) => {
        setLoading(true)
        const url = generatePath(ApiRoutes.INDIVIDUAL_EMPLOYEE_ROLE, { id: employeeRoleId })
        return axiosInstance.get(url).then((response) => {
            const roleDetails: MetaModel = deserialize(MetaModel, response.data) as MetaModel;
            setEmployeeRole(roleDetails);
            setLoading(false)
        })
    }


    const createEmployeeRole = async (roleName: string, hospitalId: string) => {
        let data: MetaModel = new MetaModel();
        try {
            setButtonLoading(true)
            const payload = { name: roleName, hospital_id: hospitalId }
            const response = await axiosInstance.post(ApiRoutes.EMPLOYEE_ROLE, payload)
            data = deserialize(MetaModel, response.data) as MetaModel;

        } catch (error) {
            console.log("Error is ", error)
        } finally {
            setButtonLoading(false)
            return data
        }
    }

    const updateEmployeeRole = (employeeRole: MetaModel) => {
        setLoading(true)
        const payload = serialize(MetaModel, employeeRole)
        const url = generatePath(ApiRoutes.INDIVIDUAL_EMPLOYEE_ROLE, { id: employeeRole.value })
        return axiosInstance.put(url, payload).then((response) => {
            const roleDetails: MetaModel = deserialize(MetaModel, response.data) as MetaModel;
            setEmployeeRole(roleDetails);
            setLoading(false)
        })
    }

    const deleteEmployeeRole = async (employeeRoleId: string) => {
        try {
            setButtonLoading(true)
            const url = generatePath(ApiRoutes.INDIVIDUAL_EMPLOYEE_ROLE, { id: employeeRoleId })
            await axiosInstance.delete(url)
            return true
        } catch (error) {
            return false
        } finally {
            setButtonLoading(false)
        }
    }

    return {
        loading,
        customAccess,
        customAccesses,
        buttonLoading,
        showCustomAccess,
        createCustomAccess,
        deleteCustomAccess,
        fetchCustomAccesses,
        updateCustomAccess,
        customAccessesPagination,
        fetchEmployeeRoleList,
        employeeRoleList,
        fetchEmployeeRole,
        employeeRole,
        updateEmployeeRole,
        deleteEmployeeRole,
        createEmployeeRole

    }
}

export default CustomAccessService