import React, { FC, useEffect, useState } from "react";
import "./staffsAndPatients.scss";
import { Tabs } from "antd";
import StaffsList from "./StaffsList";
import SearchComponent from "../../../../shared/components/SearchComponent";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import StaffForm from "./StaffsList/StaffForm";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import StaffService from "../../../../services/HospitalService/staff.service";
import PatientsList from "./PatientsList";
import { Caretaker, Patient } from "../../../../models/Patient/patient.model";
import CareTakerForm from "./CaretakerList/CaretakerForm";
import PatientService from "../../../../services/PatientService/patient.service";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import CaretakerService from "../../../../services/CaretakerService/caretaker.service";
import CaretakerList from "./CaretakerList";
import PatientForm from "./PatientsList/PatientForm";

const { TabPane } = Tabs;

interface StaffsAndPatientsProps {
  showStaffForm?: boolean
}

const StaffsAndPatients: FC<StaffsAndPatientsProps> = (props) => {
  const { showStaffForm: triggerStaffForm } = props;

  const history = useHistory();

  const location = useLocation<any>();
  const hospitalPage = parseInt(location?.state?.hospitalPage) || 1;
  const staffPage = parseInt(location?.state?.staffPage|| location?.search.split("=")[1] || 1);
  const { id } = useParams<{ id: string }>();
  const selectedTab = location?.state?.selectedKey || location.hash.substring(1) || "staff";
  const { fetchStaffs, tableLoading, staffList, totalItems } =
    StaffService();
  const {
    fetchPatients,
    patientTableLoading,
    patientList,
    patientCurrentPage,
    patientTotalItems,
  } = PatientService();
  const {
    fetchCaretakers,
    caretakerTableLoading,
    caretakerList,
    caretakerCurrentPage,
    caretakerTotalItems,
  } = CaretakerService();

  const [selectedKey, setSelectedKey] = useState<string>(selectedTab);
  const [showStaffForm, setShowStaffForm] = useState<boolean>(false);
  const [showPatientForm, setShowPatientForm] = useState<boolean>(false);
  const [showCatetakerForm, setShowCatetakerForm] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    history.location.hash ?? setSelectedKey(location.hash.replace("#", ""));
    if (selectedKey === "staff")
    fetchStaffs({
      hospital_id: id,
      search: searchText,
      page: staffPage,
      pageSize: 10
    });
  else if (selectedKey === "caretaker")
    fetchCaretakers(id, {
      search: searchText,
      page: staffPage,
      pageSize: 10
    });
  else
    fetchPatients(id, {
      search: searchText,   
      page: staffPage,
      pageSize: 10
    });
  },[location])

  useEffect(() => {
    setShowCatetakerForm(!!triggerStaffForm)
  }, [triggerStaffForm])

  const handleTabChange = (key: string) => {
    setSelectedKey(key);
    history.push({
      ...location,
      hash: key,
    });
  };

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    if (selectedKey === "staff")
      fetchStaffs({
        hospital_id: id,
        search: searchText,
      });
    else if (selectedKey === "caretaker")
      fetchCaretakers(id, {
        search: searchText,
      });
    else
      fetchPatients(id, {
        search: searchText,
      });
  };

  const handleRowClick = (record: Patient | Caretaker, type: string) =>
  record?.id &&
  history.push({
    pathname:generatePath(type === "patient" ? appRoutes.SHOW_PATIENT: appRoutes.SHOW_CARETAKER, {
      hospitalId: id,
      id: record?.id,
    }),
    state: {
      staffPage,
      hospitalPage,
      selectedKey
    }
  }
  )
  const handlePageChange = (page: number, pageSize?: number) => history.push({...location,state: {...location.state,staffPage: page}});      

  return (
    <div className="staffs-and-patients">
      <div className="staffs-and-patients__action">
        <SearchComponent onSearch={handleSearch} searchValue={searchText} setSearchValue={setSearchText}/>
        <ButtonComponent
          type="primary"
          text={`Add ${selectedKey === "staff"
            ? "staff"
            : selectedKey === "caretaker"
              ? "caregiver"
              : "patient"
            }`}
          onClick={() => {
            selectedKey === "staff"
              ? setShowStaffForm(true)
              : selectedKey === "caretaker"
                ? setShowCatetakerForm(true)
                : setShowPatientForm(true);
          }}
          className="staffs-and-patients__add"
        />
      </div>

      <Tabs
        activeKey={selectedKey}
        onChange={handleTabChange}
        destroyInactiveTabPane
      >
        <TabPane tab="Staff" key="staff">
          <StaffsList
            fetchStaffs={fetchStaffs}
            tableLoading={tableLoading}
            staffList={staffList}
            handlePageChange={handlePageChange}
            currentPage={staffPage}
            totalItems={totalItems}
            showAllStaff={false}
          />
        </TabPane>
        <TabPane tab="Patient" key="patient">
          <PatientsList
            fetchPatients={() => fetchPatients(id)}
            tableLoading={patientTableLoading}
            patientList={patientList}
            handlePageChange={handlePageChange}
            currentPage={staffPage}
            handleRowClick={handleRowClick}
            totalItems={patientTotalItems}
            showAllPatients={false}
          />
        </TabPane>
        <TabPane tab="Caregiver" key="caretaker">
          <CaretakerList
            fetchCaretakers={() => fetchCaretakers(id)}
            tableLoading={caretakerTableLoading}
            caretakerList={caretakerList}
            handlePageChange={handlePageChange}
            currentPage={staffPage}
            handleRowClick={handleRowClick}
            totalItems={caretakerTotalItems}
          />
        </TabPane>
      </Tabs>

      {
        showStaffForm && <StaffForm
          showForm={showStaffForm}
          setShowForm={setShowStaffForm}
          staff={{}}
          refreshList={() => fetchStaffs({ hospital_id: id })}
        />
      }

      <CareTakerForm
        showForm={showCatetakerForm}
        setShowForm={setShowCatetakerForm}
        caretaker={{}}
        hospitalId={id}
        refreshList={() => fetchCaretakers(id)}
      />

      <PatientForm
        showForm={showPatientForm}
        setShowForm={setShowPatientForm}
        patient={{}}
        hospitalId={id}
        refreshList={() => fetchPatients(id)}
      />
    </div>
  );
};

export default StaffsAndPatients;
