import React, { FC, useEffect, useRef, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./hospitalDetails.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { generatePath, useLocation, useParams } from "react-router-dom";
import HospitalForm from "../HospitalForm";
import StaffsAndPatients from "./StaffsAndPatients";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import HospitalService from "../../../services/HospitalService/hospital.service";
import SkeletonLoader from "../../../shared/components/SkeletonLoader";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Search from "antd/lib/transfer/search";
import GoBack from "../../../shared/components/GoBack";

interface HospitalDetailsProps { }

const HospitalDetails: FC<HospitalDetailsProps> = () => {
  const { id } = useParams();

  const history = useHistory();
  const location = useLocation<any>();
  const page = location?.state?.hospitalPage;
  const search = location?.state?.search;
  const showForm = location?.state?.showForm;
  const { showHospital, hospital, tableLoading } = HospitalService();

  const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const [showHospitalForm, setShowHospitalForm] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLSpanElement>(null)
  const rootRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
        if (descriptionRef.current) {
            descriptionRef.current.innerHTML = hospital?.bio?.replace(/\n/gm, '<br />') || "";
            descriptionRef.current.innerHTML = hospital?.bio?.replace(/<p>&nbsp;<\/p>/i, ' ') || "";
            descriptionRef.current.innerHTML = hospital?.bio?.replace(/<br>/gm, ' ') || "";
    }})
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    })
  }, [hospital?.bio, descriptionRef.current])

  useEffect(() => {
    showHospital(id);
  }, []);

  useEffect(() => {
    setShowOverflow(!!hospital?.bio && hospital?.bio?.length > 90);
  }, [hospital]);

  return (
    <div className="app-wrapper hospital-details" ref={rootRef}>
      <HospitalForm
        showForm={showHospitalForm}
        setShowForm={setShowHospitalForm}
        hospital={hospital}
        refreshHospital={showHospital}
      />

      <div className="hospital-details__basic-info">
        {tableLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            <div className="hospital-details__navigation">
              <GoBack route={{pathname: appRoutes.HOSPITALS, search: `?page=${page}${search !== ''? `&search=${search}`: ''}`, state: {hospitalPage:page, search: search}}} />
              <div className="hospital-details__name text-capitalize">
                {hospital?.name}
              </div>
              <ButtonComponent
                type="text"
                text="Departments"
                onClick={() => {
                  history.push(
                    generatePath( appRoutes.HOSPITAL_DEPARTMENTS, { id: id })
                  );
                }}
                className="hospital-details__button"
              />
              <ButtonComponent
                type="text"
                text="Roles & Responsibilities"
                onClick={() => {
                  history.push(
                    generatePath( appRoutes.HOSPITAL_ROLE, { id: id })
                  );
                }}
                className="hospital-details__button"
              />
              {/* Commented for future use -- Krishna */}
              {/* <ButtonComponent
                type="text"
                text="Locations"
                onClick={() => {
                  history.push(
                    generatePath(appRoutes.HOSPITAL_LOCATIONS, { id: id })
                  );
                }}
                className="hospital-details__button"
              /> */}
              {/* Commented for future use -- Krishna */}
              {/* <ButtonComponent
                type="text"
                text="Roles & Responsibilities"
                onClick={() => { }}
                className="hospital-details__button"
                disabled={true}
              /> */}
            </div>
            {hospital?.bio && <div className="hospital-details__bio">
              <span
                ref={descriptionRef}
                className={showOverflow ? "text-overflow" : ""}>
              </span>
              {hospital?.bio && hospital?.bio?.length > 90 && (
                <span
                  className="view-more"
                  onClick={() => setShowOverflow(!showOverflow)}
                >
                  {showOverflow ? "View more" : "View less"}
                </span>
              )}
            </div>}
            <div className="hospital-details__website">
              <a href={hospital?.website} target="_blank">
                {hospital?.website}
              </a>
            </div>
            <div className="hospital-details__communication">
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-email" />
                </span>
                <span
                  className="hospital-details__contact-info cursor-pointer"
                  onClick={(e) => {
                    window.location.href = `mailto:${hospital?.email}`;
                    e.preventDefault();
                  }}
                >
                  {hospital?.email}
                </span>
              </div>
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-mobile" />
                </span>
                <span className="hospital-details__contact-info">
                  {formatPhoneNumberIntl((hospital?.isdCode ? hospital.isdCode : "") + hospital.phone + (hospital.extCode ? "#" + hospital.extCode : ""))}
                </span>
              </div>
              {/* <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-employees" />
                </span>
                <span className="hospital-details__contact-info">
                  {hospital?.noOfEmployees} Employees
                </span>
              </div> */}
              <div className="hospital-details__contact">
                <span className="hospital-details__contact-icon">
                  <i className="icon-location" />
                </span>
                <span className="hospital-details__contact-info text-capitalize">
                  {hospital?.location}
                </span>
              </div>
            </div>
            <span
              className="hospital-details__edit"
              onClick={() => setShowHospitalForm(true)}
            >
              <i className="icon-edit-1" />
            </span>
          </>
        )}
      </div>

      <div className="hospital-details__staffs">
        <StaffsAndPatients showStaffForm={showForm} />
      </div>
    </div>
  );
};

export default HospitalDetails;
