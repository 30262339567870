import { Col, Row, } from "antd";
import React, { useEffect, useState } from "react";
import './dashboard.scss'
import DashboardCard, { DashboardCardProps } from "./DashboardCard";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from "recharts";
import { useHistory } from "react-router-dom";
import DashboardService from "../../services/DashboardService/dashboard.service";
import { JourneyStats } from "../../models/JourneyStats/journeyStats.model";
import SpinnerLoader from "../../shared/components/SpinnerLoader";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { Form, Formik } from "formik";
import DropdownField from "shared/components/DropdownField";
import moment from "moment";

const Dashboard = () => {

  const history = useHistory()

  const { fetchDashboardStats, dashboardStats, loading, overallJourneyLoading, patientData, employeesData, fetchOverallJourney } = DashboardService()

  useEffect(() => {
    fetchDashboardStats()
  }, [])

  const dashboardCardItems: DashboardCardProps[] = [
    {
      iconFillClassName: "green",
      count: dashboardStats?.hospitalCount ?? "0",
      title: "Hospital",
      onClick: () => history.push({
        pathname: appRoutes.HOSPITALS,
        search: "?showAddButton=false"
      }),
    },
    {
      iconFillClassName: "purple",
      count: dashboardStats?.employeeCount ?? "0",
      title: "Staffs",
      onClick: () => history.push(appRoutes.STAFFS_LIST)
    },
    {
      iconFillClassName: "blue",
      count: dashboardStats?.patientCount ?? "0",
      title: "Patients",
      onClick: () => history.push(appRoutes.PATIENT_LIST)
    },
    {
      iconFillClassName: "yellow",
      count: dashboardStats?.journeyCount ?? "0",
      title: "Journeys",
      onClick: () => {
        history.push({
          pathname: appRoutes.JOURNEYS,
          search: "?showAddButton=false"
        })
      }
    }
  ]

  const graphData = employeesData && patientData && (Object.keys(patientData) as (keyof typeof patientData)[]).map((item: keyof JourneyStats) => {
    return {
      name: item,
      Patient: patientData[item] ?? "",
      Employee: employeesData[item] ?? "",
    }
  })

  const currentYear = moment().year();
  const startYear = 2022;

  const yearOptions: any[] | undefined = [];
  for (let year = startYear; year <= currentYear; year++) {
    yearOptions.push({
      label: year,
      value: year
    });
  }

  const defaultYearOption = yearOptions.slice(-1)[0].label

  const [year, setYear] = useState<string>(defaultYearOption)

  useEffect(() => {
    fetchOverallJourney({
      year
    })
  }, [year])

  const navigateTothePage = (year: string, month: string, pageType: string) => {
    const numericMonth = moment(month, "MMM").format("M");
    let pathName;
    if (pageType === 'patient') {
      pathName = appRoutes.PATIENT_LIST
    } else {
      pathName = appRoutes.STAFFS_LIST
    }
    history.push({
      pathname: pathName,
      state: {
        year,
        month: numericMonth
      }
    })
  }

  return (
    <div className="app-wrapper dashboard">
      <div className="app-header">
        <div className="icon-dashboard app-header__icon" />
        <div className="app-header__title">Dashboard</div>
      </div>

      {
        !loading
          ? <Row gutter={24}>
            {
              dashboardCardItems.map((cardItem, index) =>
                <Col span={6} key={index}>
                  <DashboardCard
                    iconFillClassName={cardItem.iconFillClassName}
                    count={cardItem.count}
                    title={cardItem.title}
                    onClick={cardItem.onClick}
                  />
                </Col>
              )
            }
          </Row>
          : <SpinnerLoader />
      }

      <div className="graph-container">
        <div className="graph-title">
          <Row>
            <Col span={18}>
              <span>Overall User Journey</span>
            </Col>
            <Col span={6}>
              <Formik
                initialValues={{
                  year: "",
                }}
                onSubmit={function (values: {}): void | Promise<any> {
                  throw new Error("Function not implemented.");
                }}
              >
                <Form>
                  <DropdownField
                    name="year"
                    options={yearOptions}
                    defaultValue={defaultYearOption}
                    allowClear={false}
                    onChange={(value) => setYear(value)}
                  />
                </Form>
              </Formik>
            </Col>
          </Row>
        </div>
        {
          overallJourneyLoading
            ? <SpinnerLoader />
            : <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barCategoryGap={20}
              >
                <CartesianGrid horizontal vertical={false} strokeDasharray="1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip cursor={{ fill: 'transparent', cursor: 'pointer' }} />
                <Legend />
                <Bar dataKey="Patient" onClick={(value) => navigateTothePage(year, value.name, 'patient')} fill="#D4D4D4" radius={[12, 12, 0, 0]} />
                <Bar dataKey="Employee" onClick={(value) => navigateTothePage(year, value.name, 'employee')} fill="#A27B50" radius={[12, 12, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
        }

      </div>


    </div >
  )
}

export default Dashboard;
