import React, { useEffect, useState } from "react";
import { Menu, Popover } from "antd";
import "./appSidebar.scss";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import avatarPlaceholder from "../../../assets/userPlaceholder.png";
import sideBarLogo from "../../../assets/sidebar-logo.png";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import UserService from "../../../services/AuthService/auth.service";
import StaffBasicDetails from "../StaffBasicDetails";
import DpChip from "../DpChip";
import { TagOutlined } from "@ant-design/icons";

interface AppSidebarProps extends AuthReducerProps { }

function AppSidebar({ authenticated, user }: AppSidebarProps) {
  const history = useHistory();

  const { logoutUser } = UserService();

  const [pathname, setPathname] = useState<string>(history.location.pathname);

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const handleClick = ({ key }: any) => {
    if (key !== "profile") history.push(key);
  };

  useEffect(() => {
    return history.listen((location) => {
      setPathname("/" + location?.pathname?.split("/")[1]);
    });
  }, [history]);

  const profileContent = (
    <div className="profile-menu">
      <div
        className="profile-menu__item"
        onClick={() => setShowDetailsModal(true)}
      >
        <i className="icon-my-account" />
        <span className="app-sidebar__menu-title">My Account</span>
      </div>
      <div className="profile-menu__item" onClick={logoutUser}>
        <i className="icon-log-out" />
        <span className="app-sidebar__menu-title">Log out</span>
      </div>
    </div>
  );

  return !history?.location?.pathname?.includes("auth") && authenticated ? (
    <div className="app-sidebar">
      {user && <StaffBasicDetails
        staff={user}
        setShowDetails={setShowDetailsModal}
        showDetails={showDetailsModal}
        setCurrentStaff={() => { }}
        enablePasswordReset={true}
      />}
      <div className="app-sidebar__container">
        <div className="app-sidebar__menubar__logo">
          <img src={sideBarLogo} alt="" />
        </div>
        <Menu
          className="app-sidebar__menubar"
          onClick={handleClick}
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={[pathname]}
          mode="inline"
        >
          <Menu.Item key={AppRoutes.DASHBOARD}>
            <i
              className={`icon-dashboard ${pathname.includes("dashboard") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Dashboard</div>
          </Menu.Item>
          <Menu.Item key={AppRoutes.HOSPITALS}>
            <i
              className={`icon-hospitals ${pathname.includes("hospital") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Hospitals</div>
          </Menu.Item>
          <Menu.Item key={AppRoutes.JOURNEYS}>
            <i
              className={`icon-journey ${pathname.includes("journeys") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Journey</div>
          </Menu.Item>
          {// will be used later in phase 2
          /* <Menu.Item key={AppRoutes.SUPPORT} disabled>
            <i
              className={`icon-support ${pathname.includes("support") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Support</div>
          </Menu.Item> */}
          <Menu.Item key={AppRoutes.ARTICLES}>
            <i
              className={`icon-articles ${pathname.includes("article") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Content</div>
          </Menu.Item>
          <Menu.Item key={AppRoutes.FAQ}>
            <i
              className={`icon-faq ${pathname.includes("faq") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">FAQ</div>
          </Menu.Item>
          <Menu.Item key={AppRoutes.JOURNEY_TEMPLATES}>
            <i
              className={`icon-journey-template ${pathname.includes("journey-templates") ? "active-icon" : ""
                }`}
            />
            <div className="app-sidebar__menu-title">Journey Template</div>
          </Menu.Item>
          <Menu.Item key={AppRoutes.TAGS}>
          <TagOutlined />
            <div className="app-sidebar__menu-title">Tags Manager</div>
          </Menu.Item>
        </Menu>
        <Popover
                  placement="right"
                  title={null}
                  content={profileContent}
                  trigger="hover"
                >
        <div className="app-sidebar__profile-container" key="profile">
          <div className="app-sidebar__profile" key="profile">
            <div>
              <DpChip name={user?.role === "jos_admin"
                ? user?.name || ""
                : user?.firstName + " " + user?.lastName}
                size="large"
              />
              {/* <img
                src={avatarPlaceholder}
                className="app-sidebar__profile-img"
                alt="User Placeholder"
              /> */}
            </div>
            <div className="app-sidebar__profile-info">
              <div className="app-sidebar__profile-submenu">
                  <div className="app-sidebar__username text-capitalize">
                    {user?.role === "jos_admin"
                      ? user?.name
                      : user?.firstName + " " + user?.lastName}
                  </div>
              </div>
              <span className="app-badge">
                {user?.role === "jos_admin" ? "JOS Admin" : "JOS Admin"}
              </span>
            </div>
          </div>
        </div>
        </Popover>
      </div>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
