import React, { FC } from 'react'
import hospitalIcon from '../../../assets/svg/hospital-icon.svg'
import patientIcon from '../../../assets/svg/patient.svg'
import staffIcon from '../../../assets/svg/staff.svg'
import journeyIcon from '../../../assets/svg/journey-icon.svg'
import "./dashboardCard.scss"

export interface DashboardCardProps {
  iconFillClassName: string,
  count: string,
  title: string,
  onClick: () => void
}

const DashboardCard: FC<DashboardCardProps> = (props) => {

  const { iconFillClassName, count, title, onClick } = props

  let image = '';
  switch (iconFillClassName) {
    case 'purple':
      image = staffIcon
      break;
    case 'blue':
      image = patientIcon
      break;
    case 'green':
      image = hospitalIcon
      break;
    case 'yellow':
      image = journeyIcon
      break;
  }

  return (
    <div className="dashboard-card" onClick={onClick}>
      <div className={`icon-conianter ${iconFillClassName}`}>
        <img src={image} />
      </div>
      <div>
        <div className="stats-count">{count}</div>
        <div className="stats-name">{title}</div>
      </div>
    </div>
  )
}

export default DashboardCard