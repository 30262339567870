import React, { FC } from 'react'
import "./permissionRowDetails.scss"
import { Row, Col, Checkbox, Divider } from 'antd'
import { useField } from 'formik'
import getPermissionReadableName from './getPermissionReadableName'

interface PermissionRowDetailsProps {
    fieldName: string
}

const PermissionRowDetails: FC<PermissionRowDetailsProps> = (props) => {

    const { fieldName } = props

    const [field, meta, helpers] = useField(fieldName);
    const crudFields = ['create', 'read', 'update', 'delete']

    const setPermissionFieldValue = (name: string, value: boolean) => {
        let updatedFieldValue = {
            ...meta.value,
        }
        if (name == 'create' && value) {
            updatedFieldValue['read'] = value
        }
        updatedFieldValue[name] = value
        helpers.setValue(updatedFieldValue)
    }

    const readableName = getPermissionReadableName(fieldName)

    return (
        <>
            <Row className='top-spacing'>
                <Col span={4}>
                    <span>{readableName}</span>
                </Col>
                {
                    crudFields.map((item, index) =>
                        <Col span={index === 0 ? '4' : 5} offset={index === 0 ? '1' : '0'} key={index}>
                            <div className='text-center'>
                                <Checkbox checked={meta?.value?.[item] ?? false}
                                    onChange={() => setPermissionFieldValue(item, !meta?.value?.[item] ?? true)}
                                />
                            </div>
                        </Col>
                    )
                }
            </Row>
            <Divider />
        </>
    )
}

export default PermissionRowDetails