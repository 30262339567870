
import {serializable, alias, primitive} from 'serializr';

export class JourneyStats { 

	@serializable(alias('january', primitive()))
	Jan?: number;

	@serializable(alias('february', primitive()))
	Feb?: number;

	@serializable(alias('march', primitive()))
	Mar?: number;

	@serializable(alias('april', primitive()))
	Apr?: number;

	@serializable(alias('may', primitive()))
	May?: number;

	@serializable(alias('june', primitive()))
	Jun?: number;

	@serializable(alias('july', primitive()))
	Jul?: number;

	@serializable(alias('august', primitive()))
	Aug?: number;

	@serializable(alias('september', primitive()))
	Sep?: number;

	@serializable(alias('october', primitive()))
	Oct?: number;

	@serializable(alias('november', primitive()))
	Nov?: number;

	@serializable(alias('december', primitive()))
	Dec?: number;

}