import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import Dashboard from "../views/Dashboard";
import { RouterProps } from "../shared/types/route.type";
import AppSidebar from "../shared/components/AppSidebar";
import Hospitals from "../views/Hospitals";
import JourneyList from "../views/JourneyList";
import Support from "../views/Support";
import Articles from "../views/Articles";
import Faq from "../views/Faq";
import JourneyTemplate from "../views/JourneyTemplate";
import HospitalDetails from "../views/Hospitals/HospitalDetails";
import Departments from "../views/Hospitals/HospitalDetails/Departments";
import JourneyTemplateDetails from "../views/JourneyTemplate/JourneyTemplateDetails";
import Patients from "../views/Patients";
import Caretakers from "../views/Caretakers";
import JourneyStepForm from "../views/JourneyList/JourneyStepForm";
import JourneyDetails from "../views/JourneyList/JourneyDetails";
import FaqForm from "../views/Faq/FaqForm";
import ArticleForm from "../views/Articles/ArticleForm";
import ArticleDetails from "../views/Articles/ArticleDetails";
import Tags from "../views/Tags"
import StaffDetails from "../views/Hospitals/HospitalDetails/StaffsAndPatients/StaffsList/StaffDetails";
import RoleList from "views/Hospitals/HospitalDetails/RoleList";
import StaffList from "views/StaffList";
import PatientList from "views/PatientList";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
  const routes: RouterProps[] = [
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    { exact: true, path: appRoutes.DASHBOARD, component: isAuthenticated(Dashboard) },
    {
      exact: true,
      path: appRoutes.HOSPITALS,
      component: isAuthenticated(Hospitals),
    },
    {
      exact: true,
      path: appRoutes.SHOW_HOSPITAL,
      component: isAuthenticated(HospitalDetails),
    },
    // { exact: true, path: appRoutes.HOSPITAL_LOCATIONS, component: isAuthenticated(Locations) },
    {
      exact: true,
      path: appRoutes.HOSPITAL_DEPARTMENTS,
      component: isAuthenticated(Departments),
    },
    {
      exact: true,
      path: appRoutes.HOSPITAL_ROLE,
      component: isAuthenticated(RoleList),
    },
    {
      exact: true,
      path: appRoutes.JOURNEYS,
      component: isAuthenticated(JourneyList),
    },
    {
      exact: true,
      path: appRoutes.SUPPORT,
      component: isAuthenticated(Support),
    },
    {
      exact: true,
      path: appRoutes.ARTICLES,
      component: isAuthenticated(Articles),
    },
    { exact: true, path: appRoutes.FAQ, component: isAuthenticated(Faq) },
    {
      exact: true,
      path: appRoutes.JOURNEY_TEMPLATES,
      component: isAuthenticated(JourneyTemplate),
    },
    {
      exact: true,
      path: appRoutes.SHOW_JOURNEY_TEMPLATES,
      component: isAuthenticated(JourneyTemplateDetails),
    },
    {
      exact: true,
      path: appRoutes.SHOW_PATIENT,
      component: isAuthenticated(Patients),
    },
    {
      exact: true,
      path: appRoutes.SHOW_CARETAKER,
      component: isAuthenticated(Caretakers),
    },
    {
      exact: true,
      path: appRoutes.JOURNEY_FORM,
      component: isAuthenticated(JourneyStepForm),
    },
    {
      exact: true,
      path: appRoutes.JOURNEY_DETAILS,
      component: isAuthenticated(JourneyDetails),
    },
    {
      exact: true,
      path: appRoutes.SHOW_STAFF,
      component: isAuthenticated(StaffDetails),
    },
    {
      exact: true,
      path: appRoutes.FAQ_FORM,
      component: isAuthenticated(FaqForm),
    },
    {
      exact: true,
      path: appRoutes.ARTICLE_FORM,
      component: isAuthenticated(ArticleForm),
    },
    {
      exact: true,
      path: appRoutes.ARTICLE_VIEW,
      component: isAuthenticated(ArticleDetails),
    },
    {
      exact: true,
      path: appRoutes.TAGS,
      component: isAuthenticated(Tags),
    },
    {
      exact: true,
      path: appRoutes.STAFFS_LIST,
      component: isAuthenticated(StaffList),
    },
    {
      exact: true,
      path: appRoutes.PATIENT_LIST,
      component: isAuthenticated(PatientList),
    },
  ];

  return (
    <div>
      <Router history={appHistory}>
        <AppSidebar />
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} {...route} component={route.component as FC} />
          ))}
          <Route
            path="*"
            render={() => <Redirect to={appRoutes.DASHBOARD} />}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default AppRoutes;
