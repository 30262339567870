import React, { FC, useEffect, useRef, useState } from 'react'
import "./patientList.scss"
import { useHistory, useLocation } from 'react-router-dom';
import AppHeader from 'shared/components/AppHeader';
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import PatientsList from 'views/Hospitals/HospitalDetails/StaffsAndPatients/PatientsList';
import PatientService from 'services/PatientService/patient.service';
import patientIcon from 'assets/svg/patient.svg'


interface PatientListProps { }

const PatientList: FC<PatientListProps> = (props) => {

  const { } = props
  const history = useHistory();
  const location = useLocation<any>();
  const search = (location?.state?.search) || "";
  const page = parseInt(location?.state?.currentPage || location?.search.split("=")[1]) || 1;

  const rootRef = useRef<null | HTMLDivElement>(null);
  const [searchText, setSearchText] = useState<string>(search);

  const year = location.state?.year
  const month = location.state?.month

  const { fetchAllPatients, patientTableLoading, patientList, patientCurrentPage, patientTotalItems, } = PatientService();

  useEffect(() => {
    fetchAllPatients({
      search: search,
      page: page,
      pageSize: 10,
      year,
      month
    })
    rootRef!.current!.scrollIntoView({
      behavior: 'smooth'
    })
  }, [location])

  const handleSearch = (searchText: string) => {
    history.push({
      pathname: appRoutes.PATIENT_LIST,
      search: `?page=${1}&search=${searchText}`,
      state: { currentPage: 1, search: searchText, year, month }
    })
  };

  const handlePageChange = (page: number) => history.push(
    {
      pathname: appRoutes.PATIENT_LIST,
      search: `?page=${page}${search !== "" ? `&search=${search}` : ''}`,
      state: { currentPage: page, search: search, year, month }
    });

  return (
    <div className="app-wrapper patient-list" ref={rootRef}>
      <AppHeader
        title="Patient List"
        isSearchEnabled={true}
        searchValue={searchText}
        setSearchValue={setSearchText}
        handleSearch={handleSearch}
        showGoBack={false}
        iconImgPath={patientIcon}
      />

      <PatientsList
        fetchPatients={fetchAllPatients}
        tableLoading={patientTableLoading}
        patientList={patientList}
        handleRowClick={() => { }}
        currentPage={patientCurrentPage}
        totalItems={patientTotalItems}
        handlePageChange={handlePageChange}
        showAllPatients={true}
      />

    </div>
  )
}

export default PatientList